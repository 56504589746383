




















































export default

	props:
		items: Array
		buttons: Array

	data: ->
		active: 'none'

	mounted: ->

		# set the first one active
		@active = @item?.links?[0].id

	computed:
		gridStyle: ->
			gridTemplateColumns: "repeat(#{@items.length}, 1fr)"

		bannerAd: -> @$store.state.globals.bannerAd

		hasBannerAd: -> !!@bannerAd

	methods:
		activeClasses: (item) -> return 'active' if item.id == @active

		updateMenu: (menu) ->
			return if menu.id == @active
			@active = menu.id

		hasImage: (item) -> item?.icon

