# Setup Responsive Motion: Handle setup/cleanup of JS animations on component mount & destroy if media query matches, as well as when the query matches or unmatches.
# Use cases: Disable GSAP animations for mobile.  Disable GSAP animations if prefers-reduced-motion.
# Params: motionSetup, motionCleanup: Your setup and cleanup methods.
# Usage:
#   mounted: -> @cleanupResponsiveMotion = @$setupResponsiveMotion @motionSetup, @motionCleanup
#   destroyed: -> @cleanupResponsiveMotion()
export setupResponsiveMotion = (motionSetup, motionCleanup, mediaQueryArg='(true)') ->
	# Handle some common media queries
	if mediaQueryArg == 'reduced-motion'
		mediaQueryString = '(prefers-reduced-motion: no-preference)'
	else if mediaQueryArg == 'tablet-up'
		mediaQueryString = "(min-width: #{breakpoints.tablet})"
	else if mediaQueryArg == 'tablet-down'
		mediaQueryString = "(max-width: #{breakpoints.tablet - 1})"
	else
		mediaQueryString = mediaQueryArg
	mediaQuery = window?.matchMedia mediaQueryString
	mqListener = (mq) -> if mq.matches then motionSetup() else motionCleanup()
	mediaQuery.addListener mqListener
	# Bind listeners. This will also run motionSetup at mount, if allowed
	mqListener mediaQuery
	# Return cleanup function
	return () ->
		mediaQuery.removeListener mqListener
		motionCleanup()

export setupReducedMotion = (motionSetup, motionCleanup) ->
	setupResponsiveMotion motionSetup, motionCleanup, 'reduced-motion'
