import axios from 'axios'
import getGlobals from 'library/queries/craft/globals.gql'
import { shouldUseStaticData, getNuxtAppUrl } from 'library/helpers/routing'

# Global data
export state = ->
	hydrated: false
	defaultSeo: null
	freeGiftWithPurchase: null
	currencyCode: null

export mutations =

	# Replace the whole state
	set: (state, data) -> Object.assign state, data, hydrated: true

export actions =

	# Fetch global data
	fetch: ({ commit, $craft, $storefront } = { }) ->

		# Helpers will be set on object when this is disaptched from shopify-theme
		$craft = @$craft unless $craft
		$storefront = @$storefront unless $storefront

		# Collect data from Craft
		data = await fetchCraftData $craft

		# Replace the free gift products with an array of Shopify products
		for setting, index in (data.freeGiftWithPurchase?.settings || [])
			if setting.__typename == 'freeGiftOptions_userChoice_BlockType'
				data.freeGiftWithPurchase.settings[index].products =
					await fetchProductData $storefront, setting.products

		# This method is invoked by the static-data module and won't have a
		# commit function in that case
		if commit then commit 'set', data
		else return data

# Fetch the Craft global data, grabbing it from build json files when on
# prod shopify
fetchCraftData = ($craft) ->
	if shouldUseStaticData()
		globalsUrl = "#{getNuxtAppUrl()}/data/globals.json"
		(await axios.get globalsUrl).data
	else await $craft.execute query: getGlobals

# Fetch free gift product data from Shopify, keeping only those that are
# available
fetchProductData = ({ getProductCards }, products) ->
	handles = products.map ({ handle }) -> handle
	(await getProductCards handles)
		.filter (product) -> product.availableForSale
