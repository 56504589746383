


















import trapFocusMixin from 'library/mixins/trap-focus-mixin'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

export default

	mixins: [trapFocusMixin]

	data: -> query: ''

	computed:
		open: -> @$store.state.layout.searchOpen

	methods:
		callSetTrap: -> @setTrap(@$el)
		callReleaseTrap: -> @releaseTrap(@$el)

		# Forward to the search page with query param
		search: ->
			@$store.commit("layout/searchClose")
			@$router.push '/search?query=' + @query

	watch:
		open: (isOpen) ->
			if isOpen
				@$nextTick ->
					window.scroll({top: window.scrollY - 1}) # enforce the nav dropping in
					disableBodyScroll @$refs.scrollable
					@callSetTrap()
			else
				clearAllBodyScrollLocks()
				@callReleaseTrap()

		$route: ->
			@$store.commit("layout/searchClose")

