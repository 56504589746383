###
Functionality related to free gift with purchase feature
###
import { FREE_GIFT_ATTRIBUTE } from 'library/helpers/constants'

# Vuex module getters
export getters =

	# Helper for retrieving the free gift products
	products: (state, getters, rootState) ->
		rootState.globals.freeGiftWithPurchase?.settings[0]?.products || []

	# Make a list of all the varaint ids of free gift products. We only care
	# about the primary variant
	variantIds: (state, getters) ->
		getters.products.map ({ variants }) -> variants[0].id

	# Get the gift triggers
	giftTriggers: (state, getters, rootState) ->
		rootState.globals.freeGiftWithPurchase?.settings[0]?.giftTriggers || []

	# Whether the free gift with purchase system should be considered enabled.
	# Currently based on if there were any gift products found
	enabled: (state, getters) -> getters.products?.length > 0

	# Get the behavior of the free gift choice feature
	behavior: (state, getters, rootState) ->
		rootState.globals.freeGiftWithPurchase?.settings[0]?.behavior

	# Has the user already added free gifts to the cart
	cartHasFreeGifts: (state, getters, rootState) ->
		!!rootState.cart.lines.find (line) ->
			!!line.attributes.find ({ key }) -> key == FREE_GIFT_ATTRIBUTE

	# Help fetch the free gift threshold
	threshold: (state, getters, rootState) ->
		rootState.globals.freeGiftWithPurchase?.settings[0]?.cost

	# How much more does the user need to sped to be eligable
	remainingSpend: (state, getters, rootState, rootGetters) ->
		Math.max 0, getters.threshold - rootGetters['cart/total']

	# Does the cart meet the requirements for free gift?
	isEligible: (state, getters, rootState) ->
		return false unless getters.enabled
		return true if rootState.cart.lines.find (line) ->
			for trigger in getters.giftTriggers
				return true if trigger.slug == line.variant?.product?.handle

# Vuex module actions
export actions =

	# Remove all the free gifts from the cart
	removeAll: ({ dispatch, getters, rootState }) ->
		for line in rootState.cart.lines
			if line.variant.id in getters.variantIds
			then await dispatch 'cart/updateLine',
				id: line.id
				quantity: 0
			, root: true

export addGiftChoiceSubscribers = (store) ->

	# When above threshold, fetch the product data from Shopify.  When below,
	# remove any free products from the cart
	store.watch ((state, getters) -> getters['free-gift/choice/isEligible'])
	, (isEligible) ->
		if not isEligible then store.dispatch 'free-gift/choice/removeAll'

	# When a product is added, if it's a free product, remove any existing free
	# product. This is happening after because the await was not delaying the
	# dispatch and I was having race condition issues.
	store.subscribeAction after: ({ type, payload }) ->
		return unless type == 'cart/addItem'
		for line in store.state.cart.lines
			if line.variant.id != payload.id and
				line.variant.id in store.getters['free-gift/choice/variantIds'] and
				payload.id in store.getters['free-gift/choice/variantIds']
			then await store.dispatch 'cart/updateLine',
				id: line.id
				quantity: 0
