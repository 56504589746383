###
Shared code used to render line items in carts
###
import NumberStepper from 'library/components/globals/form/number-stepper'
import debounce from 'lodash/debounce'
export default

	props:
		item: Object

	components: {
		NumberStepper
	}

	data: ->
		quantity: @item.quantity
		loading: false

	computed:

		currentVariant: -> @item?.variant

		lineItemTotal: -> @item.estimatedCost.totalAmount.amount

		attrs: -> @item.customAttributes

		classes: -> 'is-loading' if @loading

		# Get the product type
		productType: -> @currentVariant.product.productType

		# Make the URL progromatically. Don't make a URL if a free sample, it's
		# assumed these shouldn't have PDPs.
		url: -> unless @isFree then '/products/' + @currentVariant.product.handle

		# Make a clean object to prevent errors about mutating object
		image: -> @currentVariant.image

		# Build the playload for updating the cart line, for introspection
		updateLinePayload: ->
			id: @item.id
			quantity: @quantity
			sellingPlanId: if @isSubscribing then @sellingPlanId else null

		# Helper for getting the current price
		price: -> @currentVariant.price

		# Is this a free product?
		isFree: -> parseFloat(@lineItemTotal) == 0

		# Is this a gift product
		isGift: -> @item.attributes.find ({ key }) => key == @$FREE_GIFT_ATTRIBUTE

	watch:

		# Trigger updating of the cart on a debounce
		quantity: (val,old) ->
			return if @loading
			@loading = true
			@debouncedUpdate val, old

		# Update the internal quantity if something else has touched it
		'item.quantity': (val) -> @quantity = val

	methods:

		# Trigger updating of the cart on a debounce
		debouncedUpdate: debounce (val, old) ->
			await @$store.dispatch 'cart/updateLine', @updateLinePayload
			@loading = false
		, 300

		# Delete the line
		removeLine: ->
			return if @loading
			@loading = true
			await @$store.dispatch 'cart/updateLine',
				id: @item.id
				quantity: 0
			@loading = false
