
























export default

	props:
		item: Object
		active: Boolean

	computed:

		hasDropdown: ->
			if @item?.children?.length > 0 then return true
			else return false

		classes: -> 'active' if @active

