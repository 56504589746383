


























export default
	name: "MobileNavRow"

	props:
		item: Object
		level: Number
		menuOpen: Boolean

	computed:
		hasChildren: -> @item?.children?.length

	methods:
		hasImage: (item) -> item?.icon

