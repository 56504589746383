




































import inputMixin from 'library/mixins/input-mixin'

isInteger = (val) -> !!String(val).match /^\d+$/

export default
	mixins: [inputMixin]

	props:
		min:
			type: Number
			default: 1

		max:
			type: Number
			default: 99

		value:
			type: Number | String
			validator: isInteger

		# Shows a delete icon when the next decrement results in zero
		deleteAtZero:
			type: Boolean
			default: false

		# Loading state is used in flyout menu
		loading: Boolean

	# Overwrite state watcher to parse integers
	data: -> state: parseInt @value

	methods:
		#(don't check bounds on state change to allow clearing while typing)
		# Increment by 1 and check bounds
		increase: ->
			return if @loading
			@state = @applyBounds @state + 1

		# Decrement by 1 and check bounds
		decrease: ->
			return if @loading
			@state = @applyBounds @state - 1

		# Check that value is within provided range
		applyBounds: (val) -> Math.min @max, Math.max @min, val

		# Check the range if valid, otherwise set to min
		onBlur: ->
			if isInteger @state then @state = @applyBounds @state
			else @state = @min

	computed:

		ariaLabel: -> if @label then "#{@label} Quantity" else 'Quantity'

		showTrash: -> @deleteAtZero && @state == 1

		removeDisabled: ->
			if !@deleteAtZero then @state == @min
			else false


