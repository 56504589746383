






import logo from 'library/assets/images/text-logo.svg'

export default

	data: -> { logo }

