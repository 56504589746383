###
Notification bar data
###

export state = ->
	height: 0
	close: false

export mutations =

	# Set data
	setData: (state, data) -> state.data = data

	# Set the notification bar height
	setHeight: (state, height) ->
		state.height = height

	# Set close
	setClose: (state, close) ->
		state.close = close

