














































import CountrySelector from 'library/components/globals/country-selector/country-selector'
import EndCap from './end-cap'
import 'vue-height-tween-transition/index.css'
import HeightTween from 'vue-height-tween-transition'

export default

	components: {
		CountrySelector
		EndCap
		HeightTween
	}

	data: -> openPanel: null

	computed:
		footerNav: -> @$store.state.globals.footerNavigation
		footerNavItems: -> @footerNav?.children || []
		contactInfo: -> @$store.state.globals.contactInfo?.fullWysiwyg

	methods:
		toggleAccordion: (id) ->
			if @openPanel == id
			then @openPanel = null
			else @openPanel = id

