













































































import GiftChoice from './gift-choice/gift-choice'
import CartCosts from './cart-costs'
import LineItem from './line-item'
import RecommendedProduct from './recommended-product'
import SsrCarousel from 'vue-ssr-carousel'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'
import trapFocusMixin from 'library/mixins/trap-focus-mixin'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import dimensions from 'library/assets/vars/dimensions'
import breakpoints from 'library/assets/vars/breakpoints'
import 'vue-height-tween-transition/index.css'
import HeightTween from 'vue-height-tween-transition'

export default

	components: {
		GiftChoice
		CartCosts
		LineItem
		RecommendedProduct
		SsrCarousel
		HeightTween
	}

	mixins: [trapFocusMixin]

	data: ->
		touchStart: null
		recommendedProducts: []
		offset: '0px'
		config:
			slidesPerPage: 2
			showArrows: false
			showDots: false
			paginateBySlide: true
			peekRight: 40
			gutter: 20

	computed:

		# Vuex accessors
		cart: -> @$store.state.cart
		lineItems: -> @$store.state.cart.lines
		hasItems: -> @$store.getters['cart/itemCount'] > 0
		checkoutUrl: -> @cart.checkoutUrl
		freeShipping: -> @$store.getters['cart/subtotal'] > 50
		recommended: -> @$store.state.globals.cart.products
		open: -> @$store.state.cart.open

		# Make the string containing the cart count
		cartCount: ->
			count = @$store.getters['cart/itemCount']
			"#{count} #{if count == 1 then 'ITEM' else 'ITEMS'}"

		notificationHeight: -> @$store.state['notification-bar'].height

		scrollY: -> @$store.state.layout.scrollY

		offsetHeight: ->
			return unless window?

			headerHeight =
				if window.innerWidth > parseInt breakpoints.tablet
				then parseInt dimensions['header-h']
				else parseInt dimensions['header-h-mobile']

			height = @notificationHeight + headerHeight

			if @scrollY <= @notificationHeight then (height - @scrollY) + 'px'
			else headerHeight + 'px'

	fetch: ->
		@recommendedProducts = await @$mergeShopifyProductCards @recommended

	methods:

		closeCart: ->
			@$store.commit 'cart/close'
			clearAllBodyScrollLocks()
			html = document.querySelector 'html'
			html.style.height = ''

		callSetTrap: -> @setTrap(@$el)
		callReleaseTrap: -> @releaseTrap(@$el)

		# Start tracking swipe movement, in order to close the cart on -> swipe
		handleTouchStart: (event) ->
			# Only procceed if not too close to the edge (prevents browser navigation event)
			if event.page < 30 then event.preventDefault(); return

	 		# Only one finger touching screen
			if (event.touches.length == 1) then @touchStart = event.touches.item(0).clientX;
			else @touchStart = null # A second finger hit the screen, abort the touch

		# Check where the swipe movement finished and close cart
		handleTouchEnd: (event) ->
			offset = 100 # needs at least 100px to configure a swipe
			if @touchStart
				end = event.changedTouches.item(0).clientX

				# Left -> Right Swap
				if (end > @touchStart + offset) then @closeCart()

	watch:
		open: (isOpen) ->
			if isOpen
				@$nextTick ->
					window.scroll({top: window.scrollY - 1}) # enforce the nav dropping in
					disableBodyScroll @$refs.scrollable
					html = document.querySelector 'html'
					html.style.height = '100vh'
					@callSetTrap()
			else
				clearAllBodyScrollLocks()
				html = document.querySelector 'html'
				html.style.height = ''
				@callReleaseTrap()

		# Update the height of the items container when a new item is added or removed
		'lineItems.length': ->
			return unless @open
			@$refs.container.style.height = @$refs.items.clientHeight + 'px'
			@$defer => @$refs.container.style.height = @$refs.items.clientHeight + 'px'
			@$wait 500, => @$refs.container.style.height = ''

