












import logo from 'library/assets/images/affirm-logo-v2.webp'
export default
	props:
		variant: Object
		bundledVariants: Array

	data: -> {
		# An array of skus to check if price should be compared to bundled price
		logo
	}


	computed:
		price: ->
			if @isBundle
			then @bundlePrice
			else @$formatMoney @unformattedPrice

		priceFormatted: -> @removeTrailingZeroes @price

		isUS: -> @$storefront.country?.toLowerCase() == 'us'

		unformattedPrice: -> @variant?.price?.amount || @variant?.price

		affirmBasePrice: -> ( Number(@price.slice(1))/12 ).toFixed(2)

		compareAtExists: ->
			return @variant?.compareAtPrice?.amount and
			@variant?.compareAtPrice?.amount != "0.0"

		compareAt: ->
			if @isBundle
				@$formatMoney @bundleCompareAtPrice
			else if @compareAtExists
				@$formatMoney @variant?.compareAtPrice?.amount
			else
				false

		compareAtFormatted: -> @removeTrailingZeroes @compareAt

		showCompareAt: -> @compareAt and @price != @compareAt

		bundlePrice: ->
			componentVariantsTotal = @bundledVariants?.reduce (sum, {price}) ->
				sum + parseFloat price?.amount || price
			, parseFloat @unformattedPrice
			@$formatMoney @unformattedPrice - (componentVariantsTotal - @bundleResultPrice)


		bundleCompareAtPrice: -> @unformattedPrice

		isBundle: -> !!@bundledVariants?.length and !!@bundleResultVariant

		bundleResultVariant: ->
			return unless !!@bundledVariants?.length
			@$store.getters['bundles/getBundleResult'] [
				@variant.sku
				...@bundledVariants.map ({sku}) -> sku
			]

		bundleResultPrice: ->
			@bundleResultVariant?.price?.amount || @bundleResultVariant?.price

	methods:
		removeTrailingZeroes: (price) ->
			if price.endsWith '00'
			then price.slice(0, price.length - 3)
			else price
