















import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import trapFocusMixin from 'library/mixins/trap-focus-mixin'

export default
	name: "MobileNav"

	mixins: [trapFocusMixin]

	props:
		open: Boolean
		items: Array

	watch:
		open: (isOpen) ->
			if isOpen
				disableBodyScroll @$refs.scrollable
				@callSetTrap()
			else
				clearAllBodyScrollLocks()
				@callReleaseTrap()

	methods:
		callSetTrap: -> @setTrap(@$el)
		callReleaseTrap: -> @releaseTrap(@$el)


