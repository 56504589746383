
















import getTower from '~/queries/tower.gql'
import pageMixin from '@cloak-app/craft/mixins/page'

export default
	name: 'Error'

	mixins: [ pageMixin ]

	props: ['error']

	data: ->
		page: null

	# Get get the tower data
	fetch: ->
		@page = await @$craft.getEntry
			query: getTower
			variables: { @uri }

	# Show Sentry user input dialog if an error
	mounted: -> @showSentryDialog() if @uri == 'error'

	computed:

		# Figure out which error Tower to show
		uri: -> switch @error?.statusCode
			when 404 then 'page-not-found'
			else 'error'

		# Simple error message
		message: -> switch @error?.statusCode
			when 404 then 'Page Not Found'
			else 'An Error Occured'

	methods:

		# Show the sentry dialog
		# https://docs.sentry.io/enriching-error-data/user-feedback/?platform=browser
		showSentryDialog: -> @$defer => @$sentry?.showReportDialog()

