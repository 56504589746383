




















import GiftChoiceOption from './gift-choice-option'
import GiftChoiceAddAll from './gift-choice-add-all'
import SsrCarousel from 'vue-ssr-carousel'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'

export default

	components: {
		GiftChoiceOption
		GiftChoiceAddAll
		SsrCarousel
	}

	props: products: Array

	data: -> config:
		slidesPerPage: 3
		gutter: 'var(--cart-pad-h)'
		showDots: true
		responsive: [
			maxWidth: 425
			slidesPerPage: 2
		]

