



































import DropdownMenu from './dropdown-menu'
import DesktopTopLevel from './top-level'
import HeaderDropdown from './header-dropdown'
import ClickOutside from 'vue-click-outside'

export default

	components: {
		HeaderDropdown
		DropdownMenu
		DesktopTopLevel
	}

	directives: {
		ClickOutside
	}

	data: ->
		selectedNav: null
		mouseOverDropdown: false

	mounted: ->
		document.addEventListener 'scroll', @onScroll

	destroyed: ->
		document.removeEventListener 'scroll', @onScroll

	computed:
		active: -> @selectedNav?.title
		navigationData: -> @$store.state.globals.desktopNavigation
		navigationItems: -> @navigationData?.navigationMenus || []
		open: -> !!@selectedNav?.children
		selectedNavId: -> @selectedNav?.id

	methods:

		classes: (item) ->
			if item.title == @active then return 'show'

		onScroll: -> @hideDropdown()

		hasDropdown: (item) ->
			if item?.children?.length > 0 then return true
			else return false

		# from click outside directive
		hideDropdown: ->
			@selectedNav = null
			@mouseOverDropdown = false

		dropdownEnter: ->
			@mouseOverDropdown = true

		dropdownLeave: ->
			@mouseOverDropdown = false
			@closeDropdown()

		openDropdown: (navItem) ->
			@openWaitId = @$wait 200, =>
				@selectedNav = navItem

		closeDropdown: ->
			clearTimeout @openWaitId
			@$wait 20, => @selectedNav = null unless @mouseOverDropdown



	watch:

		# Close the nav when the route changes
		$route: ->
			@selectedNav = null

		selectedNav: (nav) ->
			if !nav then return @hideDropdown()

			if !@hasDropdown(nav) then return @hideDropdown()

			return if !nav?.children.length

			# @$nextTick =>
			# 	el = @$refs[nav.title][0]
			# 	el.querySelectorAll('[role="button"]')[0].focus()

