






































import DetachableHeader from 'vue-detachable-header'
import 'vue-detachable-header/index.css'
import dimensions from 'library/assets/vars/dimensions'
import SiteLogo from './site-logo'
import CartCount from './cart-count'
import CartIcon from './cart-icon'
import Hamburger from './hamburger'

import MobileNav from 'library/components/globals/mobile-nav'

export default

	components: {
		DetachableHeader
		SiteLogo
		CartCount
		CartIcon
		Hamburger
		MobileNav
	}

	data: ->
		menuOpen: false
		height: parseInt dimensions['header-h-mobile']


	computed:
		mobileNavigation: -> @$store.state.globals.mobileNavigation

		navigationMenus: -> @mobileNavigation.navigationMenus

		offset: -> @$store.state['notification-bar']?.height || 0

	watch:
		'$route': -> @close()

	methods:
		toggleMobileNav: -> @menuOpen = !@menuOpen

		close: -> @menuOpen = false

