import { render, staticRenderFns } from "./bar.vue?vue&type=template&id=59f42e16&scoped=true&lang=pug&"
import script from "./bar.vue?vue&type=script&lang=coffee&"
export * from "./bar.vue?vue&type=script&lang=coffee&"
import style0 from "./bar.vue?vue&type=style&index=0&id=59f42e16&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59f42e16",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Wysiwyg: require('/opt/build/repo/node_modules/@bkwld/cloak/components/wysiwyg.coffee').default,SmartLink: require('/opt/build/repo/nuxt-app/components/globals/smart-link.coffee').default})
