###
Boot up cart related logic
###
import { addTealiumCartSubscribers } from 'library/services/tealium/cart-events'
import { bundlesCartSubscribers } from 'library/services/bundles'
import { lazyHydrateCart } from 'library/services/cart/hydrate-cart'
import { addGiftChoiceSubscribers } from 'library/store/free-gift/choice'
import { watchForQueryDiscountCodes } from 'library/services/discounts'
export default ({ app, store }, inject) ->
	addTealiumCartSubscribers store, app.$tealiumEcomm
	bundlesCartSubscribers store
	lazyHydrateCart store
	addGiftChoiceSubscribers store
	watchForQueryDiscountCodes store, app.router
