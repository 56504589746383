























export default

	computed:
		endCapNav: -> @$store.state.globals.endCapNavigation?.children
		socialLinks: -> @$store.state.globals.socialLinks?.socialLinks

