# Deps
import throttle from 'lodash/throttle'
import breakpoints from 'library/assets/vars/breakpoints.json'

# Settings
tablet = parseInt breakpoints.tablet
navBreak = parseInt breakpoints['nav-break']

# Variables related to the general layout of the page
export state = ->

	# Viewport size
	viewport:
		width: null
		height: null
	bodyOffset: 0
	searchOpen: false
	scrollY: null

export mutations =

	setViewportDimensions: (state, dimensions) -> state.viewport = dimensions
	setBodyOffset: (state, offset) -> state.bodyOffset = offset
	setScrollY: (state, scrollY) -> state.scrollY = scrollY

	# Open and close the search
	searchOpen: (state) -> state.searchOpen = true
	searchClose: (state) -> state.searchOpen = false

export getters =

	# Return boolean only if viewport has been measued
	isDesktop: (state) ->
		if state.viewport.width then state.viewport.width >= tablet
	usingDesktopHeader: (state) ->
		if state.viewport.width then state.viewport.width >= navBreak
	isMobile: (state) ->
		if state.viewport.width then state.viewport.width < tablet
	usingMobileHeader: (state) ->
		if state.viewport.width then state.viewport.width < navBreak



# Shared method for adding listeners to update the store
export createListeners = (store) ->

	# Store the throttled window size
	updateDimensions = ->
		store.commit 'layout/setViewportDimensions',
			width: window.innerWidth
			height: window.innerHeight

	updateScrollPosition = ->
		store.commit 'layout/setScrollY', window.scrollY

	# DRY up setting window resize listeners and return a cleanup function
	onResize = (callback, throttleMs = 100) ->
		throttledCallback = throttle callback, throttleMs
		window.addEventListener 'resize', throttledCallback
		callback()
		return () -> window.removeEventListener 'resize', throttledCallback

	onScroll = (callback, throttleMs = 100) ->
		throttledCallback = throttle callback, throttleMs
		window.addEventListener 'scroll', throttledCallback
		callback()
		return () -> window.removeEventListener 'scroll', throttledCallback

	# Add initial listeners
	onResize updateDimensions
	onScroll updateScrollPosition

	# Return the resize helper
	return { onResize, onScroll }


export adjustBody = (store) ->
	bodyHeightOffset = 0

	interval = setInterval((->
		if document.body.style.marginTop
			bodyHeightOffset = parseInt document.body.style.marginTop
		else
			bodyHeightOffset = 0

		if bodyHeightOffset != store.state.layout.bodyOffset
		then store.commit 'layout/setBodyOffset', offset: bodyHeightOffset

	), 500)

