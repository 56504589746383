




















import 'vue-height-tween-transition/index.css'
import HeightTween from 'vue-height-tween-transition'
export default

	components: { HeightTween }

	data: -> open: true

	computed:

		# Choose which message to render
		message: -> switch @$store.getters["free-gift/choice/behavior"]
			when 'selectOne' then 'Select your FREE gift'
			when 'receiveAll' then 'Add your FREE gifts'

