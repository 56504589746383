


















export default

	props: block: Object

