###
Helpers related to formating values
###
import atob from 'atob-lite'
import colors from 'library/assets/vars/colors.json'

# Format money for a product, which may be free
export productCost = (val) ->
	if parseFloat(val) == 0 then 'Free'
	else @$formatMoney val

# Convert to kebab case with optional prefix.  Good for generating a CSS class name from a variable.
# Examples:
#  kebabCase('Image Left')  >>> 'image-left'
#  kebabCase('Image Right', 'layout')  >>> 'layout-image-right'
export kebabCase = (str, prefix='') ->
	return '' unless typeof str == 'string'
	kebab = str
		.toLowerCase() # To lowercase
		.replace(/\s+/g, '-') # Replace spaces with hyphens
		.replace(/-{2,}/g, '-') # Replace multiple consecutive hyphens with a single hyphen
	return kebab unless prefix
	"#{prefix}-#{kebab}"


# Get foreground color based on background color
export getFgFromBg = (bg) ->
	darkColors = [
		colors['primary-color']
		colors['primary-color-dark']
		colors['brown-grey']
	]

	if bg in darkColors then 'white' else colors['primary-color']

# Wrap the json ld data in the stucture needed by vue meta
export makeTag = (data) ->
	type: 'application/ld+json'
	json: { '@context': 'http://schema.org', ...data }
	hid: 'json-ld-' + data['@type'].toLowerCase()

# Extract inner text from tags in a html string, and optionally
# add spaces to separate text within different tags
export extractInnerTextFromHTMLString = (str, withSpace) ->
	return unless process.client
	span = document.createElement('span')
	span.innerHTML = str
	if withSpace
		children = span.querySelectorAll('*')
		for i in [i..children.length-1]
			if children[i].textContent
				children[i].textContent+= ' '
			else
				children[i].innerText+= ' '

	[span.textContent || span.innerText].toString().replace(/ + /g, ' ')

# Remove duplicate objects from array
export uniqueArray =  (arr) => arr.filter (value, index) =>
  _value = JSON.stringify(value);
  index == arr.findIndex (obj) =>
    JSON.stringify(obj) == _value;
