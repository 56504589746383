###
Send Tealium dataLayer events in response to cart actions
###

# Main function that adds the subscribers
export addTealiumCartSubscribers = (store, tealiumEcomm) ->

	# Stores the quantity of a line item before the mutation is changed
	beforeSnapshot = {}

	# Delegate relevant actions
	store.subscribeAction

		# Capture the line item quantity before the mutation
		before: ({ type, payload }) ->
			return unless type == 'cart/updateLine'
			lineItem = getLineItem payload.id
			beforeSnapshot[lineItem.id] =
				quantity: lineItem.quantity
				variantId: lineItem.variant.id

		# Fire the events
		after: ({ type, payload }) ->
			switch type
				when 'cart/updateLine' then fireUpdateItem payload
				when 'cart/addItem' then fireAddItem payload
				when 'cart/addItems' then fireAddItems payload

	# Subscribe to open mutation for cart views
	store.subscribe ({ type, payload }) ->
		switch type
			when 'cart/open' then fireViewCart()

	# Viewed cart
	fireViewCart = ->
		await store.dispatch 'cart/fetchUnlessHydrated'
		tealiumEcomm.viewCart store.state.cart.id

	# Item added to cart
	fireAddItem = ({ id, quantity }) ->
		await store.dispatch 'cart/fetchUnlessHydrated'
		tealiumEcomm.addToCart id, quantity, store.state.cart.id

	# Multiple Items added to cart
	fireAddItems = ({ variantIds, quantity }) ->
		await store.dispatch 'cart/fetchUnlessHydrated'
		tealiumEcomm.addToCart variantIds, quantity, store.state.cart.id

	# Item quantity updated
	fireUpdateItem = ({ id: lineItemId, quantity }) ->
		await store.dispatch 'cart/fetchUnlessHydrated'

		# Figure out the change in quantity
		line = getLineSnapshot lineItemId
		diff = quantity - line.quantity
		return if diff == 0

		# Fire events if there was a change. Using the variant id from the
		# snapshot for the case of a line that was deleted, we won't be able
		# to lookup the variant from it.
		cartId = store.state.cart.id
		if diff > 0
		then tealiumEcomm.addToCart line.variantId, diff, cartId
		else tealiumEcomm.removeFromCart line.variantId, Math.abs(diff), cartId

	# Lookup the line item
	getLineItem = (lineItemId) ->
		lineItem = store.state.cart.lines.find (lineItem) ->
			lineItem.id == lineItemId
		unless lineItem then throw "Line not found: #{lineItemId}"
		return lineItem

	# Get the line snapshot
	getLineSnapshot = (lineItemId) ->
		unless line = beforeSnapshot[lineItemId]
		then throw "Line shapshot not found: #{lineItemId}"
		return line
