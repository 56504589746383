



















export default

	props: product: Object

	computed:
		image: -> @product?.images?[0]

		productUrl: -> "/products/#{@product.handle}"

		firstVariant: -> @product.variants[0]

