













export default
	props:
		open: Boolean


