


















export default
	props:
		type:
			type: String
			default: "Affirm"
			validator: (val) -> val in [
				"Affirm",
				"30-Night Satisfaction",
				"HSA/FSA Approved",
				"Free 2-Day Shipping"
			]

	computed:
		contents: ->
			switch @type
				when "30-Night Satisfaction" then @$store.state.globals.nightSatisfactionGuaranteeModal
				when "HSA/FSA Approved" then @$store.state.globals.hsaFsaApprovedModal
				when "Free 2-Day Shipping" then @$store.state.globals.free2DayShippingModal
				else @$store.state.globals.affirmModal

		classes: -> [
			switch @type
				when "30-Night Satisfaction" then "night-satisfaction"
				when "HSA/FSA Approved" then "hsa-fsa-approved"
				when "Free 2-Day Shipping" then "free-2-day-shipping"
				else "affirm"
		]

		copy: -> @contents?.bodyCopy
		image: -> @contents?.image

		sizes: -> """
		(min-width: 768px) 400px, (max-width: 767px) 100vw
		"""

