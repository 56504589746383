
































import inputMixin from 'library/mixins/input-mixin'
export default

	mixins: [ inputMixin ]

	props:

		# Type of textfield
		type:
			type: String
			default: 'text'

		half:
			type: Boolean
			default: false

		noBorder:
			type: Boolean
			default: false

		name:
			type: String
			default: ''

		readonly:
			type: Boolean
			default: false

		inlineButton:
			type: Boolean
			default: false

		placeholder:
			type: String
			default: null

		autocomplete:
			type: String
			default: 'off'

		autocorrect:
			type: String
			default: 'on'

		autocapitalize:
			type: String
			default: 'on'

		minlength:
			type: String
			default: '1'

		maxlength:
			type: String
			default: '100'

		error:
			type: Boolean
			default: false

		pattern: String

		ariaDescribedby:
			type: String
			default: null

	computed:

		classes: -> [
			'half' if @half
			'no-border' if @noBorder
			'invalid' if @error
		]

		inputClasses: -> [
			'inline-button' if @inlineButton
		]

		computedPlaceholder: -> "#{@placeholder || @label}#{if @required then ' *' else ''}"
		computedLabel: -> "#{@label}#{if @required then ' *' else ''}"

	# Constrain length when type is number
	methods: keydown: () ->
		return unless @maxlength and @type == 'number'
		maxlength = Number @maxlength
		if @value.length > maxlength then @value = @value.slice 0, maxlength

