import throttle from 'lodash/throttle'
import dimensions from 'library/assets/vars/dimensions.json'
import { createListeners } from 'library/store/layout'
import { adjustBody } from 'library/store/layout'
export default ({ app, store }, inject) ->

	# Keep VueX up to date
	{ onResize, onScroll } = createListeners store
	inject 'onResize', onResize
	inject 'onScroll', onScroll

	adjustBody store

	# Update the offset of the anchor scroller
	store.watch(
		(state) -> state.layout.viewport.width,
		(winW) ->
			headerH = if (window.innerWidth >= 	768)
			then dimensions['header-h']
			else dimensions['mobile-header-h']
	)
