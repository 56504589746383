






































import CouponEntry from './coupon-entry'
import AffirmCta from 'library/components/globals/affirm-cta'
freeShippingMin = 50.00 # $50.00 is the hardcoded free shipping cutoff

export default
	components: {
		CouponEntry
		AffirmCta
	}

	computed:
		# Get the subtotal
		subtotal: -> @$store.getters['cart/subtotal']

		# Get the total, ignoring tax at this time
		total: -> @$store.getters['cart/total'] - @$store.getters['cart/tax']

		# Simplify the discount codes
		discountCodes: -> @$store.state.cart.discountCodes.map ({ code }) -> code

		# Helper to check if discount code is a free shipping code
		shippingDiscount: -> @discountCodes.some (code) ->
			shippingCode = /^(FREESHIP-|HOLIDAYSHIP)/
			return (shippingCode.test code.toUpperCase())

		# Make the label for the shipping costs
		shippingCost: -> switch
			when !@remaingShippingMoney or @shippingDiscount
				@$t 'cart.free_shipping_on_order'

		# Remaing shipping cost as a string
		remaingShippingMoney: ->
			return if @total >= freeShippingMin
			@$formatMoney freeShippingMin - @total

		isUS: -> @$storefront.country?.toLowerCase() == 'us'

	methods:

		# Remove discount codes
		clearDiscounts: -> @$store.dispatch 'cart/removeDiscounts'

