































export default
	name: "MobileNavMenuLink"

	props:
		item: Object
		level: Number
		menuOpen: Boolean

	data: ->
		open: false

	computed:
		label: -> "Open #{@item.title} Submenu"

		hasImage: -> @item?.icon

		children: -> @item?.children

		subMenuTitle: ->
			if @item?.subMenuTitle
			then @item?.subMenuTitle
			else @item?.title

		buttons: -> @item?.buttons

	methods:
		toggleOpen: -> @open = !@open

		close: -> @open = false

		closeAll: ->
			@close
			@$emit('closeAll')

	watch:
		menuOpen: (isOpen) ->
			if !isOpen then @open = false

