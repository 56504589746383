import merge from 'lodash/merge'
import get from 'lodash/get'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import cloneDeep from 'lodash/cloneDeep'

# Bundle data
export state = ->
	hydrated: false
	# Bundles is more efficient for looking up the products currently in cart
	# It is simply an array of bundle objects that will be looped through when an
	# item is added to the cart
	bundles: []


export getters =
	# Use an array of skus to get a bundled result
	getBundleResult: (state, {variantBundleMap}) => (variantsSkus) =>
		result = get variantBundleMap, variantsSkus.join '.'
		result?.resultVariant

	# variantBundleMap is more efficient for looking up the possible bundles on PDP
	# It is a multip nested object where each level the keys are the sku of variants
	# making up the bundle, allowing for effective lookup while building the PDP
	variantBundleMap: ({bundles}) ->
		# Create a Variant Bundle Object for each bundle and merge it all together
		variantBundleMap = {}
		for bundle in bundles
			merge variantBundleMap,	buildVariantBundleObj bundle, bundle.id
		variantBundleMap


export mutations =

	# Replace the whole state
	set: (state, data) -> Object.assign state, data, hydrated: true

export actions =

	# Fetch bundles
	fetch: ({ commit, $craft, $storefront } = { }) ->
		craft = $craft || @$craft
		storefront = $storefront || @$storefront

		# Get data
		{bundles} = await craft.execute query: """
			query($site: [String]) {
				bundles: entries(
					section: "bundles"
					site:$site
					) {
					id
					componentVariants: variants {
						id
						title
						slug
						productSlug
					}
					resultVariant: variant2 {
						id
						title
						slug
						productSlug
					}
				}
			}
		"""

		# Filter out bundles that may have received an empty or disabled variant
		bundles = bundles.filter (bundle) ->
			!!bundle?.componentVariants?.length && !!bundle?.resultVariant?.length

		# Sort bundles by component variant list length so we can check bigger
		# bundles first and swap those out first
		bundles = bundles.sort ({componentVariants: a},{componentVariants: b}) ->
			a.length >= b.length

		# Get the price info for the result variant so we can compare prices
		handles = uniq flatten bundles.map (bundle) ->
			resultSlug = bundle.resultVariant[0].productSlug
			componentVariantSlugs = bundle.componentVariants.map (variant) ->
				variant.productSlug

			[resultSlug, ...componentVariantSlugs]

		# Fetch data from shopify in one query
		products = await storefront.getProductCards handles

		# Map into an object for easy access
		products = products.reduce (acc, product) ->
			acc[product.handle] = product
			acc
		, {}

		# Rebuild bundles with the shopify data if the data was successfully fetched
		bundles = bundles.map (bundle, idx) =>
			resultVariant = formatData bundle.resultVariant[0], products
			componentVariants = bundle.componentVariants.map (variant) =>
				newVariant = formatData variant, products
				newVariant

			return {
				id: bundle.id
				componentVariants
				resultVariant
			}

		# Filter out bundles where the component total is more than the result cost
		bundles = bundles.filter (bundle) ->
			componentTotal = bundle.componentVariants?.reduce (sum, {price}) ->
				sum + parseFloat price?.amount || price
			, 0.00
			componentTotal > parseFloat bundle.resultVariant.price?.amount || bundle.resultVariant.price

		if commit then commit 'set', { bundles }
		else return { bundles }


# MISC HELPER FUNCTIONS FOR BUNDLING ###########################################

# Replace the bundles with an object of product keys to make it quicker to
# filter bundles available per product
# We do this in globals so that it'll be done once and not on every PDP page
formatSKU = (sku) ->
	return unless sku
	sku.split('-').join('').toUpperCase()

# Helper function to merge our craft variant with fetched shopify data
formatData = (craftVariant, shopifyProducts) ->
	# Format the craft slug back into sku (FeedMe changes it somewhere)
	craftVariant.sku = formatSKU craftVariant.slug
	# Get the product from the craft variant product slug
	shopifyVariants = shopifyProducts?[craftVariant.productSlug]?.variants
	shopifyData = shopifyVariants?.find (variant) =>
		variant.sku == craftVariant.sku
	if shopifyData == undefined then shopifyData = {}
	else
		shopifyData.shopifyId = shopifyData.id

	return {
		...shopifyData
		...craftVariant
	}


# Takes a bundle and recursively creates a mapping of the skus leading to
# the resultVariant
buildVariantBundleObj = ({componentVariants, resultVariant}, bundleId) ->
	result = {}
	# If we only have one variant, break recursion and create an object with
	# the result variant keyed
	if componentVariants.length == 1
		variant = componentVariants[0]
		variantSku = formatSKU variant.slug
		result[variantSku] = {
			resultVariant: resultVariant
			bundleId
		}
	# Otherwise build out a "Variant Bundle Object" for every variant at key
	else
		for variant, idx in componentVariants
			variantSku = formatSKU variant.slug
			# Make a copy of the array and get all elements except current
			otherVariants = [
				...componentVariants.slice 0, idx
				...componentVariants.slice idx+1
			]
			result[variantSku] = buildVariantBundleObj {
				componentVariants: otherVariants
				resultVariant
			}, bundleId
	result
