




















import Vue from 'vue'

# Mapping of Craft _typename to component
export mapping =
	blocks_assetAndCta_BlockType: 'blocks-asset-and-cta'
	blocks_copy_BlockType: 'blocks-copy'
	blocks_mediaAsset_BlockType: 'blocks-media-asset'
	blocks_imageCarousel_BlockType: 'blocks-image-carousel'
	blocks_productCarousel_BlockType: 'blocks-product-carousel'
	blocks_simpleMarquee_BlockType: 'blocks-simple-marquee'
	blocks_marquee_BlockType: 'blocks-marquee'
	blocks_spacer_BlockType: 'blocks-spacer'
	blocks_storyScroll_BlockType: 'blocks-story-scroll'
	blocks_tabs_BlockType: 'blocks-tabs'
	blocks_wrapper_BlockType: 'blocks-wrapper'
	blocks_mediaAssetRepeater_BlockType: 'blocks-media-asset-repeater'
	blocks_faqAccordion_BlockType: 'blocks-faq-accordion'
	blocks_bannerCta_BlockType: 'blocks-banner-cta'
	blocks_productAndAccordion_BlockType: 'blocks-product-and-accordion'
	blocks_lifestyleImagery_BlockType: 'blocks-lifestyle-imagery'
	blocks_productComparison_BlockType: 'blocks-product-comparison'
	blocks_productReview_BlockType: 'blocks-product-reviews'
	blocks_iconGrid_BlockType: 'blocks-icon-grid'
	blocks_appCards_BlockType: 'blocks-app-cards'
	blocks_bulletCards_BlockType: 'blocks-bullet-cards'
	blocks_imageCta_BlockType: 'blocks-image-and-cta'
	blocks_blogListing_BlockType: 'blocks-blog-listing'
	blocks_productListing_BlockType: 'blocks-product-listing'
	blocks_linkGrid_BlockType: 'blocks-link-grid'
	blocks_faqListing_BlockType: 'blocks-faq-listing'
	blocks_marqueeSimplified_BlockType: 'blocks-marquee-simplified'
	blocks_breadcrumbs_BlockType: 'blocks-breadcrumbs'
	blocks_embed_BlockType: 'blocks-embed'
	blocks_greenhouseJobList_BlockType: 'blocks-greenhouse'
	blocks_contactForm_BlockType: 'blocks-form'

# Hard import marquee components so they don't get chunked
import '~/components/blocks/simple-marquee'

# Component def for this block
export default
	name: 'BlockList'

	props:
		blocks: Array

		# Used by Wrapper so the children don't get an offset of zero and disable
		# lazly loading. Note, this isn't perfect because any blocks after the
		# wrapper block won't currently be offset by the preceeding wrapper's
		# children count, but it works for our simple need of enabling lazy loading.
		indexOffset:
			type: Number
			default: 0

	computed:

		# Filter the blocks to those that have been defined.  To fix errors in dev
		# environments after content model is created but commits with the new
		# block component have not been pulled down.
		createdBlocks: ->
			(@blocks || [])
			.map (block) -> {
					...block
					componentName: mapping[block.__typename]
				}
			.filter (block) -> !!block.componentName

