

























export default

	props: product: Object

	data: ->
		adding: false # Add to cart loading state

	computed:
		firstVariant: -> @product.variants[0]

	methods:
		# Add product to cart
		addToCart: ->
			@adding = true
			await @$store.dispatch 'cart/addItem',
				id: @product.variants[0].id
			@adding = false

