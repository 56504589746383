
































import DetachableHeader from 'vue-detachable-header'
import SiteLogoText from './site-logo-text'
import CartCount from './cart-count'
import CartIcon from './cart-icon'
import 'vue-detachable-header/index.css'
import dimensions from 'library/assets/vars/dimensions'
import DesktopNav from 'library/components/globals/desktop-nav/desktop-nav'
import SearchTray from './search-tray'
export default

	components: {
		SiteLogoText
		CartCount
		CartIcon
		DetachableHeader
		DesktopNav
		SearchTray
	}

	data: -> height: parseInt dimensions['header-h']

	computed:
		offset: -> @$store.state['notification-bar']?.height || 0
		searchOpen: -> @$store.state.layout.searchOpen

	methods:
		toggleSearch: ->
			if @searchOpen
			then @$store.commit("layout/searchClose")
			else @$store.commit("layout/searchOpen")

