# Deps
import Vue from 'vue'

# Modal
import Modal from '@bkwld/vue-modal'
Vue.component 'modal', Modal
import '@bkwld/vue-modal/index.css'

# Helper for explicit links
import SmartLink from '~/components/globals/smart-link'
Vue.component 'smart-link', SmartLink

# Shopify Visual
import ShopifyVisual from 'library/components/globals/shopify-visual'
Vue.component 'shopify-visual', ShopifyVisual
