# Inject globals only to the client
# This includes animation libraries, libraries that require the window object, etc.

# Greensock and GSAP ScrollTrigger plugin
import { gsap, ScrollTrigger } from 'gsap/all'

# Scroll Trigger: Set defaults
ScrollTrigger.defaults({

	# Pause tweens as soon as they're off screen
	toggleActions: "play pause reverse reset"
	start: "top bottom"
	end: "bottom top"
	# markers: true

	# Tween the scrolled elements for 1 second.
	scrub: 0
})

# Register ScrollTrigger plugin with GSAP
gsap.registerPlugin(ScrollTrigger)

# Inject client-only libraries
export default ({ app, store }, inject) ->
	inject 'gsap', gsap
	inject 'ScrollTrigger', ScrollTrigger

# In Viewport directive
import Vue from 'vue'
import inViewportDirective from 'vue-in-viewport-directive'
inViewportDirective.defaults.margin = '-10% 0%'
Vue.directive('in-viewport', inViewportDirective)

# Cart Flyout
import CartFlyout from 'library/components/layout/cart-flyout/cart-flyout'
Vue.component 'cart-flyout', CartFlyout

# Number Stepper
import FormNumberStepper from 'library/components/globals/form/number-stepper'
Vue.component 'form-number-stepper', FormNumberStepper
