import { render, staticRenderFns } from "./menu-link.vue?vue&type=template&id=7cc51efc&scoped=true&lang=pug&"
import script from "./menu-link.vue?vue&type=script&lang=coffee&"
export * from "./menu-link.vue?vue&type=script&lang=coffee&"
import style0 from "./menu-link.vue?vue&type=style&index=0&id=7cc51efc&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cc51efc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CraftVisual: require('/opt/build/repo/node_modules/@bkwld/cloak/components/craft-visual.coffee').default,Btn: require('/opt/build/repo/nuxt-app/components/globals/btn/btn.vue').default,MobileNavPanel: require('/opt/build/repo/library/components/globals/mobile-nav/panel.vue').default})
