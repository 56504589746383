






















import {applyDiscount, hasDiscounts} from 'library/services/discounts'
import debounce from 'lodash/debounce'
export default

	data: ->
		couponCode: ''
		error: false
		loading: false

	methods:
		addDiscount: debounce ->
			@loading = true
			await applyDiscount @$store, @couponCode
			@error = !hasDiscounts(@$store)
			@loading = false
		, 300

