import { render, staticRenderFns } from "./recommended-product.vue?vue&type=template&id=862f0b44&scoped=true&lang=pug&"
import script from "./recommended-product.vue?vue&type=script&lang=coffee&"
export * from "./recommended-product.vue?vue&type=script&lang=coffee&"
import style0 from "./recommended-product.vue?vue&type=style&index=0&id=862f0b44&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "862f0b44",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShopifyVisual: require('/opt/build/repo/library/components/globals/shopify-visual.coffee').default,SmartLink: require('/opt/build/repo/nuxt-app/components/globals/smart-link.coffee').default,ProductPrice: require('/opt/build/repo/library/components/globals/product/price.vue').default})
