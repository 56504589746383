















import logo from 'library/assets/images/affirm-logo-v2.webp'

export default
	data: -> { logo }

