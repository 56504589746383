













export default {

	methods:

		onMouseover: ->
			@$emit('mouseover')

		onMouseleave:->
			@$emit('mouseleave')

}

