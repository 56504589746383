# Deps
import axios from 'axios'
import get from 'lodash/get'
import { getShopifyId } from '@cloak-app/shopify/helpers'
import formatDate from 'date-fns/format'

# Main class, which accepts configuration in it's constructor and exposes
# helper methods
export default class ShopifyTealiumInstrumentor

	# Save settings and hydrate vars
	constructor: ({
		@debug = false
		@$storefront
		@currencyCode = null
	} = {}) ->

	# API ########################################################################

	# There's a tealium extension that listen to these events in order to fire
	# rockerbox conversions
	addToCartEvent: new Event('cart_add')

	# Page View (fired on Towers)
	pageView: ( page ) ->
		@_pushEvent 'page_view', {
			page_name: page.title
			page_type: if page.uri == "__home__" then "home" else "page"
			site_section: "page"
		}

	# Content View (fired on Blogs)
	contentView: ( blog ) ->
		@_pushEvent 'content_view', {
			content_author: blog.author?.fullName
			content_id: blog.id
			content_publish_date: formatDate (new Date blog.dateCreated), 'yyyy/MM/dd'
			content_title: blog.title
			page_name: blog.title
			page_type: "blog"
			site_section: "blog"
		}

	# Product View/Variant View (fired on PDP variants)
	productView: (variantId, variantView) ->
		# Get and map variants
		variants = await @_fetchVariants [variantId]
		variantData = if variants then @_buildVariantData variants else {}

		# Which event we should send
		# Product view used when landing on PDP, variant view used when
		# interacting with the variant selector
		event = if variantView then 'variant_view' else "product_view"
		pageInfo = if variantView then {site_section: "product", page_type: "variant"} else { site_section: "product", page_type: "product" }

		@_pushEvent event, {
			...pageInfo
			...variantData
		}

	# Colleciton view
	categoryView: (collection, productSkus) ->
		@_pushEvent 'category_view', {
			category_id: collection.id
			category_name: collection.title
			page_name: collection.title
			page_type: "category"
			products_on_page: productSkus
			site_section: "category"
		}

	# Email Signup
	emailSignup: (email) ->
		@_pushEvent 'email_signup', {	customer_email: email	}
		return unless process.client
		# Tealium extension listening to this event in order to fire rockerbox conversion pixel
		window.dispatchEvent new CustomEvent('email_signup', {detail: email})

	# Cart Add
	addToCart: (variantId, quantity, cartId) ->
		@_updateCart variantId, quantity, cartId, 'cart_add'
		# Tealium extension listening to this event in order to fire rockerbox conversion pixel
		return unless process.client
		window.dispatchEvent(@addToCartEvent)

	# Cart Remove
	removeFromCart: (variantId, quantity, cartId) ->
		@_updateCart variantId, quantity, cartId, 'cart_remove'

	# Private Update Cart Helper
	# variantPayload can be a single id or an array of multiple
	_updateCart: (variantPayload, quantity, cartId, eventType='cart_update') ->
		# Put single variants into an array to DRY fetch calls
		if typeof variantPayload == "string"
			variantIds = [ variantPayload ]
		else
			variantIds = variantPayload

		# Get and map variants
		variants = await @_fetchVariants variantIds
		variantData = if variants then @_buildVariantData variants, quantity else {}

		# Get and map cart
		cart = await @_fetchCart cartId
		cartData = if cart then @_buildCartData cart else {}

		@_pushEvent eventType, {
			...variantData
			...cartData
		}

	# Cart View
	viewCart: (cartId) ->
		# Get and map cart
		cart = await @_fetchCart cartId
		cartViewData = if cart then @_buildCartViewData cart else {}

		@_pushEvent 'cart_view', {

			## This page related parameters force tealium GA tag to trigger a page
			## view event, commenting these out to avoid triggering page view events
			## when openning the cart

			# page_type: 'cart'
			# site_section: 'cart'

			...cartViewData
		}


	# PRIVATE SHOPIFY DATA FUNCTIONS #############################################

	# Lookup a product variant by id. It may be a simple number or a
	# gid://shopify string
	_fetchVariants: (variantIds) ->
		return unless variantIds
		variantIds = variantIds.map (variantId) ->
			btoa 'gid://shopify/ProductVariant/' + getShopifyId variantId
		{ nodes: variants } = await @$storefront.execute
			variables: ids: variantIds
			query: fetchVariantsQuery
		return variants

	# Lookup a cart by id. Id should be a gid://shopify string
	_fetchCart: (cartId) ->
		return unless cartId

		# Get the data
		{ cart } = await @$storefront.execute
			query: fetchCartQuery
			variables: id: cartId

		# Final massage of Carts into Checkout
		if cart.estimatedCost
			cart.subtotalPrice = cart.estimatedCost.subtotalAmount.amount
			cart.totalPrice = cart.estimatedCost.totalAmount.amount

		# Return "checkout" (which could be a Cart object)
		return cart

	# Map the Variants into Tealium formatted data keys
	_buildVariantData: (variants, quantity) ->
		# Helper to get values mapped from a key
		variantValuesFromKey = (key) ->
			variants.map (variant) -> get variant, key

		# Product Quantity separate so it doesn't generate an empty key
		productQuantity = if quantity then {
			product_quantity: Array(variants.length).fill String quantity
		} else {}

		# The object keys to be returned
		{
			product_id: (variantValuesFromKey 'id').map getShopifyId
			product_price: variantValuesFromKey 'price.amount'
			product_unit_price: variantValuesFromKey 'price.amount'
			product_sku: variantValuesFromKey 'sku'
			product_variant: variantValuesFromKey 'title'
			product_name: variantValuesFromKey 'product.title'
			...productQuantity
		}

	# Map the cart into Tealium formatted data keys (focused on link events)
	_buildCartData: (cart) ->
		lineItems = cart?.lineItems
		# Make quantities available for cart_total_items and cart_product_quantity
		quantities = lineItems.map (item) -> item?.quantity

		# The object keys to be returned
		cart_id: getShopifyId cart.id
		cart_total_items: String quantities.reduce ((qty1, qty2) -> qty1 + qty2), 0
		cart_total_value: cart.subtotalPrice?.amount
		cart_product_id: lineItems.map (item) -> getShopifyId item?.variant?.id
		cart_product_price: lineItems.map (item) -> item?.variant?.price?.amount
		cart_product_quantity: quantities.map (qty) -> String qty
		cart_product_sku: lineItems.map (item) -> item?.variant?.sku


	# Map the cart into Tealium formatted data keys (focused on view events)
	_buildCartViewData: (cart) ->
		lineItems = cart?.lineItems
		# Make quantities available for cart_total_items and cart_product_quantity
		quantities = lineItems.map (item) -> item?.quantity

		# The object keys to be returned
		cart_id: getShopifyId cart.id
		cart_total_items: String quantities.reduce ((qty1, qty2) -> qty1 + qty2), 0
		cart_total_value: cart.subtotalPrice?.amount
		product_id: lineItems.map (item) -> getShopifyId item?.variant?.id
		product_price: lineItems.map (item) -> item?.variant?.price?.amount
		product_unit_price: lineItems.map (item) -> item?.variant?.price?.amount
		product_quantity: quantities.map (qty) -> String qty
		product_sku: lineItems.map (item) -> item?.variant?.sku
		product_name: lineItems.map (item) -> item?.variant?.product?.title
		product_variant: lineItems.map (item) -> item?.variant?.title



	# PRIVATE DATALAYER WRITING ##################################################

	# Push Tealium Event
	_pushEvent: (name, payload) ->

		# Merge common payload vars in
		payload = {
			tealium_event: name
			country_code: @$storefront.country?.toLowerCase()
			language_code: @$storefront.language?.toLowerCase()
			order_currency_code: @currencyCode
			customer_ip: window.clientIP
			...payload
		}

		# Remove the language code during checkout because Shopify is failing to
		# persist their language choice. For more info, see:
		# shopify-theme/snippets/locale_codes.liquid
		if window.usingFallbackLanguage
		then delete payload.language_code

		# Support debugging
		if @debug then console.debug "'#{name}'", payload

		# View type but cart_view events all use the view method
		if name.includes('view') and name != 'cart_view'
		then @_view payload
		else @_link payload


	# utag Methods ###############################################################

	# Wait for window to have utag loaded
	_utagLoaded: -> new Promise (resolve) ->
		checkLoaded = ->
			if window?.utag?.loader?.ended then resolve()
			else setTimeout checkLoaded, 100
		checkLoaded()

	# Wrap the utag.link so we can wait for load
	_link: (...args) ->
		await @_utagLoaded()
		window.utag.link(...args)

	# Wrap the utag.view so we can wait for load
	_view: (...args) ->
		await @_utagLoaded()
		window.utag.view(...args)


# STOREFRONT QUERIES ###########################################################

# Product Variant fragment
productVariantFragment = '''
	fragment variant on ProductVariant {
		id
		sku
		title
		price: priceV2 { amount }
		compareAtPrice: compareAtPriceV2 { amount }
		image { originalSrc }
		product {
			id
			title
			handle
			productType
			vendor
		}
	}
'''

# Graphql query to fetch multiple variants by ids
fetchVariantsQuery = """
	query getVariantsForTealium(
		$ids: [ID!]!
		$country: CountryCode
		$language: LanguageCode)
		@inContext(country: $country, language: $language) {

		nodes(ids: $ids) {
			...variant
		}
	}
	#{productVariantFragment}
"""

# Graphql query to fetch a cart by id
fetchCartQuery = """
	query getCartForTealium(
		$id: ID!
		$country: CountryCode
		$language: LanguageCode)
		@inContext(country: $country, language: $language) {

		cart(id: $id) {
			... on Cart {
				id
				webUrl: checkoutUrl
				estimatedCost {
					subtotalAmount { amount }
					totalAmount { amount }
				}
				lineItems: lines (first: 250) {
					edges {
						node {
							... on CartLine {
								id
								quantity
								variant: merchandise { ...variant }
							}
						}
					}
				}
			}
		}
	}
#{productVariantFragment}
"""

# NON-INSTANCE HELPERS ########################################################

# Error object with custom handling
class StorefrontError extends Error
	name: 'StorefrontError'
	constructor: (errors, payload) ->
		super errors.map((e) -> e.debugMessage || e.message).join ', '
		@errors = errors.map (e) -> JSON.stringify e
		@payload = payload
