































export default
	name: "MobileNavPanel"

	props:
		items: Array
		level:
			type: Number
			default: 1
		subMenuTitle: String
		subMenuIcon: Array
		buttons: Array
		menuOpen: Boolean

	computed:
		hasButtons: -> @buttons?.length

		hasSubMenuTitle: -> @subMenuTitle?.length

	watch:
		'$route': -> @close()

	methods:
		close: -> @$emit 'close'

		closeAll: -> @$emit 'closeAll'


