import { render, staticRenderFns } from "./gift-choice-add-all.vue?vue&type=template&id=173b7a6d&scoped=true&lang=pug&"
import script from "./gift-choice-add-all.vue?vue&type=script&lang=coffee&"
export * from "./gift-choice-add-all.vue?vue&type=script&lang=coffee&"
import style0 from "./gift-choice-add-all.vue?vue&type=style&index=0&id=173b7a6d&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "173b7a6d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Btn: require('/opt/build/repo/nuxt-app/components/globals/btn/btn.vue').default})
