# Store the client ip in a global variable, to be later passed to tealium events
# Facebook connector for Facebook Conversions API needs the ip address for CCPA compliance
import axios from 'axios'

getClientIP = () ->
	try
		response = await axios.get "https://api.ipify.org?format=json"
		if response.status == 200 then window.clientIP = response.data.ip

getClientIP()
