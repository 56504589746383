













export default

	data: -> mounted: false

	# Need to hide until mounted to prevent SSR issues because the value of the
	# getter changes client side
	mounted: -> @mounted = true

	computed:

		# VueX accessor
		cartQty: -> @$store.getters['cart/itemCount']

