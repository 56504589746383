import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=61245f91&scoped=true&lang=pug&"
import script from "./footer.vue?vue&type=script&lang=coffee&"
export * from "./footer.vue?vue&type=script&lang=coffee&"
import style0 from "./footer.vue?vue&type=style&index=0&id=61245f91&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61245f91",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmartLink: require('/opt/build/repo/nuxt-app/components/globals/smart-link.coffee').default,CountrySelector: require('/opt/build/repo/library/components/globals/country-selector/country-selector.vue').default})
