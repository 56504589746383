












































import groupBy from 'lodash/groupBy'
import au from './flags/au.png'
import benelux from './flags/benelux.png'
import ca from './flags/ca.png'
import ch from './flags/ch.png'
import de from './flags/de.png'
import es from './flags/es.png'
import fr from './flags/fr.png'
import gb from './flags/gb.png'
import it from './flags/it.png'
import nordics from './flags/nordics.png'
import us from './flags/us.png'
export default

	data: ->
		open: false
		flags: {
			au
			ca
			ch
			de
			dk: nordics
			es
			fi: nordics
			fr
			gb
			it
			nl: benelux
			be: benelux
			no: nordics
			sv: nordics
			us
		}

	computed:

		# Format nuxt/i18n locales as expected by this plugin
		locales: -> @$i18n.locales.map (locale) =>

			# Leaving this here until @cloak-app/i18n gets updated
			# This uses fr translation strings which be will use
			if locale.countryCode == 'be'
				tempCode = 'fr'
				tempName = 'Belgique'
			else
				tempcode = locale.countryCode
				tempName = @$t "locale_selector.countries.#{locale.countryCode}"

			{
				...locale
				handle: locale.site
				lang: locale.languageCode
				name: (name = tempName)
				url: locale.domain + (locale.path || '')

				# The group name is also used as the title for a locale row
				groupName: switch locale.countryCode
					when 'dk', 'fi', 'no', 'sv' then 'Nordics'
					when 'nl', 'be' then 'Benelux'
					else name
			}

		# Group the locales by the group name
		groupedLanguages: -> groupBy @locales, 'groupName'

		# Get the current locale object
		currentLocale: ->
			locale = @locales.find ({ code }) => code == @$i18n.locale
			unless locale then locale = @locales.find ({ code }) => code == 'en-US'
			return locale

		localeCode: -> @currentLocale.countryCode

		countries: ->
			Object.keys(@groupedLanguages).map (country) =>
				name: country
				code: @groupedLanguages[country][0]?.countryCode

		# Remove current country and sort it, since it's already active. Then
		# order alphabetically, since the translated names will be different
		countryDropdown: ->
			@countries.filter ({ code }) => code != @currentLocale.countryCode
			.sort (a, b) -> a.name.localeCompare(b.name)

	methods:
		getCountryFlag: (countryCode) -> @flags[countryCode]
		getCountryLanguages: (country) ->	@groupedLanguages[country]
		langSlug: (language) -> language.lang.toUpperCase()
		toggleDropdown: -> @open = !@open

