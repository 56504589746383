






































export default

	props: product: Object

	data: -> loading: false

	computed:

		# The gift choice behavior
		behavior: -> @$store.getters["free-gift/choice/behavior"]

		# Can this option be added to cart
		addsToCart: -> @behavior == 'selectOne'

		# Get the primary variant of this product
		primaryVariant: -> @product.variants[0]

		# Vuex shorthand
		cartLines: -> @$store.state.cart.lines

		# Get the cart line if it's in the cart
		productCartLine: -> @cartLines.find (line) =>
			line.variant.id == @primaryVariant.id

		# Is this already in the cart?
		isInCart: -> !!@productCartLine

	methods:

		# Add or remove from cart
		onClick: ->
			return if @loading or not @addsToCart
			try
				@loading = true
				unless @isInCart
				then await @$store.dispatch 'cart/addItem',
					id: @primaryVariant.id
					quantity: 1
					attributes: [@$FREE_GIFT_ATTRIBUTE]: @$FREE_GIFT_VALUE
				else await @$store.dispatch 'cart/updateLine',
					id: @productCartLine.id
					quantity: 0
			finally @loading = false

