






import logo from 'library/assets/images/logo.svg'

export default

	data: -> { logo }

