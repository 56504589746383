


import { isDark } from '@bkwld/light-or-dark'
import VideoModal from 'library/components/globals/modals/video'
import CopyImageModal from 'library/components/globals/modals/copy-image'

export default

	props:

		# URL to link to
		to: String

		# Size of the button
		size:
			type: String
			validator: (val) -> val in ['small']

		# If a <button>, what type is it
		type:
			type: String
			default: 'button'
			validator: (val) -> val in ['button', 'submit', 'reset']

		theme:
			type: String
			default: 'solid'
			validator: (val) -> val in [
				'solid', 'outline', 'text', 'inverted', 'alternate', 'icon', 'pointer']

		color:
			type: String
			default: 'primary'
			validator: (val) -> val in [
				'dark-blue', 'teal', 'white'
				#Color Aliases
				'primary', 'secondary'
			]

		fill:
			type: String
			default: 'solid'
			validator: (val) -> val in ['solid', 'inverted', 'outline']

		# Don't add any styling, intended for adding accessibility features to
		# button-like elements
		basic:
			type: Boolean
			default: false

		# Label intended for aria-label
		label:
			type: String

		# States
		loading: Boolean
		disabled: Boolean

		# Should the button open a modal
		modalFunction:
			type: String
			validator: (val) -> val in [
				'none'
				'videoModal'
				'affirmModal'
				'nightSatisfactionGuaranteeModal'
				'hsaFsaApprovedModal'
				'free2DayShippingModal'
			]

	computed:

		colorComputed: -> switch @color
			when 'primary' then return 'dark-blue'
			when 'secondary' then return 'teal'
			else return @color

		# Root classes
		classes: -> [
			"size-#{@size}" if @size
			if @videoModal then "theme-video"
			else if @copyImageModal then 'theme-affirm theme-text'
			else "theme-#{@theme}" if @theme
			"color-#{@colorComputed}" if @colorComputed
			"fill-#{@fill}" if @fill
			loading: @loading
		]

		textColor: ->
			return unless @color.length
			if isDark @color then 'white' else 'black'

		# States that should supress clicks
		nonInteractive: -> @disabled or @loading

		videoModal: -> @modalFunction == 'videoModal'

		affirmModal: -> @modalFunction == 'affirmModal'

		nightSatisfactionGuaranteeModal: -> @modalFunction == 'nightSatisfactionGuaranteeModal'

		hsaFsaApprovedModal: -> @modalFunction == 'hsaFsaApprovedModal'

		free2DayShippingModal: -> @modalFunction == 'free2DayShippingModal'

		copyImageModal: -> [
			'affirmModal'
			'nightSatisfactionGuaranteeModal'
			'hsaFsaApprovedModal'
			'free2DayShippingModal'
		].includes @modalFunction

	methods:

		onClick: (e) ->
			# Open Video Modal and prevent default behavior
			# (video modal btns shouldn't have other behavior)
			if @videoModal
				e.preventDefault()
				@mountVideoModal()
				return

			# Open Affirm Modal and prevent default behavior
			# (modal btns shouldn't have other behavior)
			if @affirmModal
				e.preventDefault()
				@mountAffirmModal()
				return

			if @nightSatisfactionGuaranteeModal
				e.preventDefault()
				@mountNightSatisfactionGuaranteeModal()
				return

			if @hsaFsaApprovedModal
				e.preventDefault()
				@mountHsaFsaApprovedModal()
				return

			if @free2DayShippingModal
				e.preventDefault()
				@mountFree2DayShippingModal()
				return

			# Prevent clicking if non-interactive
			if @nonInteractive
			then e.preventDefault()
			else @$emit 'click', e

		mountVideoModal: ->
			await @$mountOnBody VideoModal,
				parent: this
				propsData: { videoUrl: @to }

		mountAffirmModal: ->
			await @$mountOnBody CopyImageModal,
				parent: this
				propsData: { type: "Affirm" }

		mountNightSatisfactionGuaranteeModal: ->
			await @$mountOnBody CopyImageModal,
				parent: this
				propsData: { type: "30-Night Satisfaction" }

		mountHsaFsaApprovedModal: ->
			await @$mountOnBody CopyImageModal,
				parent: this
				propsData: { type: "HSA/FSA Approved" }

		mountFree2DayShippingModal: ->
			await @$mountOnBody CopyImageModal,
				parent: this
				propsData: { type: "Free 2-Day Shipping" }

	# Conditionally change the root button type
	render: (create) ->

		# Make the shared data object
		data =
			class: ['btn', ...@classes]
			domProps: disabled: @nonInteractive
			on: click: @onClick

		# The contents of the button
		children = [
			create 'span', class: 'shape'
			create 'span', class: 'slot', @$nonEmpty [
				@$slots.default
				if @loading then create 'span', class: 'icon-spinner'
			]
			create 'span', class: 'icon-arrow' if @theme == 'text'
			create 'span', class: 'icon-play' if @videoModal
			create 'span', class: 'pointer' if @theme == 'pointer'
		]

		# Only add accesibility related features to the button
		if @basic
			create 'div', {
				on:
					click: @onClick
				attrs:
					role: 'button'
					tabindex: '0'
					"aria-label": @label
			}, @$slots.default
		# Render a smart-link
		else if @to and !@videoModal
			create 'smart-link', {
				...data
				attrs: {
					...data.attrs
					role: 'button'
				}
				props: { @to }
			}, children

		# Render an html button
		else
			create 'button', {
				...data
				domProps: {
					...data.domProps
					@type
				}
			}, children

