###
Bundles cart related services
###

export bundlesCartSubscribers = (store) ->

	store.subscribeAction
		after: ({ type }) ->
			switch type
				when 'cart/addItem' then checkCartForBundle()
				when 'cart/addItems' then checkCartForBundle()

	# Look through our cart lines, and swap each bundle we find
	# repeat till we've exhausted all bundle potential
	checkCartForBundle = () ->
		bundleStore = store.state.bundles

		bundles = bundleStore.bundles
		cartLines = [...store.state.cart.lines]

		bundleHit = findFirstBundle bundles, cartLines
		return unless bundleHit

		await swapCartBundle bundleHit, cartLines
		checkCartForBundle()

	# Search for the first bundle subset using skus
	findFirstBundle = (bundles, lines) ->

		cartSkus = lines.map (line) -> line.variant.sku

		bundles.find (bundle) ->
			bundleSkus = bundle.componentVariants.map (variant) -> variant.sku
			bundleSkus.every (sku) -> cartSkus.includes sku

	# Update our cart with the removed items and the new item
	swapCartBundle = ({componentVariants, resultVariant}, lines) ->
	 	# Set bundling state to true, in order to prevent cart from closing
		# while bundling process happens
		await store.commit 'cart/setBundling', true
		for componentVariant in componentVariants
			line = lines.find ({ variant: lineVariant }) ->
				lineVariant.id == componentVariant.shopifyId
			payload =
				quantity: line.quantity - 1
				id: line.id
			await store.dispatch 'cart/updateLine', payload

		await store.dispatch 'cart/addItem', {
			id: resultVariant.shopifyId
		}

		# Reste bundling state to false so cart can close when emptied
		await store.commit 'cart/setBundling', false
