





















































import cartLine from 'library/mixins/cart-line'

import 'vue-height-tween-transition/index.css'
import HeightTween from 'vue-height-tween-transition'

export default

	components: { HeightTween }

	mixins: [ cartLine ]

