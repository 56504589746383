














export default

	data: -> adding: false

	computed:

		# Are all gifts added to cart at once
		addsAllToCart: ->
			@$store.getters['free-gift/choice/behavior'] == 'receiveAll'

		# Does the cart already have free gifts
		cartHasGifts: -> true

		# Make the add all to cart payload
		addAllPayload: ->
			quantity: 1
			variantIds: @$store.getters['free-gift/choice/variantIds']
			attributes: [@$FREE_GIFT_ATTRIBUTE]: @$FREE_GIFT_VALUE

	methods:

		# Add all options to cart
		addGiftsToCart: ->
			return if @adding
			@adding = true
			try await @$store.dispatch 'cart/addItems', @addAllPayload
			catch e then console.error e
			@adding = false

