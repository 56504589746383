import { render, staticRenderFns } from "./gift-choice-selector.vue?vue&type=template&id=30b4ff9a&scoped=true&lang=pug&"
import script from "./gift-choice-selector.vue?vue&type=script&lang=coffee&"
export * from "./gift-choice-selector.vue?vue&type=script&lang=coffee&"
import style0 from "./gift-choice-selector.vue?vue&type=style&index=0&id=30b4ff9a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30b4ff9a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SsrCarousel: require('/opt/build/repo/node_modules/vue-ssr-carousel/index.js').default})
