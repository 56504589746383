















import GiftChoiceDropdown from './gift-choice-dropdown'
import GiftChoiceSelector from './gift-choice-selector'
import 'vue-height-tween-transition/index.css'
import HeightTween from 'vue-height-tween-transition'

export default
	components: {
		GiftChoiceDropdown
		GiftChoiceSelector
		HeightTween
	}

	computed:

		# Helpers to make template easier to read
		enabled: -> @getter 'enabled'
		behavior: -> @getter 'behavior'
		cartHasFreeGifts: -> @getter 'cartHasFreeGifts'
		isEligible: -> @getter 'isEligible'
		remainingSpend: -> @getter 'remainingSpend'
		products: -> @getter 'products'

		# Show the menu if it's enabled and we're not in the recievesAll behavior
		# with a customer whose already clicked it
		shouldRender: ->
			return unless @enabled
			return if @behavior == 'receiveAll' and @cartHasFreeGifts
			return true

	methods:

		# Helper for accessing the right getter
		getter: (property) -> @$store.getters["free-gift/choice/#{property}"]

