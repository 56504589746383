
















import Bar from './bar.vue'
import timing from 'library/assets/vars/timing.json'
viewedBarsKey = 'viewed-bar'
export default

	components: { Bar }

	# Store the bar to show as data (rather than computed) so it's appearance
	# timing can be controlled
	data: ->
		shownBar: null
		viewedBars: null

	# Set the initial bar a couple seconds after cookies accepted.
	# Note: cookie handling is not currently implemented
	mounted: ->
		@$wait timing['notification-bar-delay'], => @show()

	computed:
		# Get the current bar unless it's already been viewed
		currentBar: -> @$store.state.globals.notificationBar

		# Force close the notification bar
		close: -> @$store.state['notification-bar'].close


	watch:
		shownBar: ->
			localStorage.setItem viewedBarsKey, true

		close: (close) ->
			if close
				@shownBar = null

	methods:

		# When closing, set the height back to 0
		onLeave: -> @$store.commit 'notification-bar/setHeight', 0

		show: ->
			@shownBar = @currentBar

