/**
 * Settings and configs that are shared in multiple places
 */


// The root paths that are handled by Shopify. These get auto-redirected to.
export const SHOPIFY_ROOT_PATHS = [
	'cart',
	'pages',
	'apps',
	'\\w+\/checkouts',
	'\\w+\/orders',
	'challenge',
	'tools',
]

// Cookie name for the count of items in the cart
export const CART_COUNT_KEY = 'cartCount'

// Cookie name for the storefront api cart id
export const CART_ID_KEY = 'cartId'

// UTM query params
export const UTM_KEYS = [
	'utm_source',
	'utm_medium',
	'utm_campaign',
	'utm_term',
	'utm_content',
	'gclid',
]

// Free gift line item attribute
export const FREE_GIFT_ATTRIBUTE = 'Free Gift'
export const FREE_GIFT_VALUE = 'Yes'

// Robots TXT configurations
const ALL_ROBOTS = {
	Sitemap: `${process.env.URL}/sitemap.xml`,
	UserAgent: '*',
	Disallow: () => [
		'/admin',
		'/cart',
		'/orders',
		'/checkouts',
		'/checkout',
		'/10043036/checkouts',
		'/10043036/orders',
		'/carts',
		'/account',
		'/collections/*sort_by*',
		'/*/collections/*sort_by*',
		'/collections/***',
		'/collections/*%2B*',
		'/collections/*%2b*',
		'/*/collections/***',
		'/*/collections/*%2B*',
		'/*/collections/*%2b*',
		'/blogs/***',
		'/blogs/*%2B*',
		'/blogs/*%2b',
		'/*/blogs/***',
		'/*/blogs/*%2B*',
		'/*/blogs/*%2b*',
		'/*?*oseid=*',
		'/*preview_theme_id*',
		'/*preview_script_id*',
		'/policies/',
		'/*/*?*ls=*&ls=*',
		'/*/*?*ls%3D*%3Fls%3D*',
		'/*/*?*ls%3d*%3fls%3d*',
		'/search',
		'/apple-app-site-association',
	]
}

const ADSBOT_GOOGLE_ROBOTS = {
	Sitemap: `${process.env.URL}/sitemap.xml`,
	UserAgent: 'adsbot-google',
	Disallow: () => [
		'/checkouts/',
		'/checkout',
		'/carts',
		'/orders',
		'/10043036/checkouts',
		'/10043036/orders',
		'/*?*oseid=*',
		'/*preview_theme_id*',
		'/*preview_script_id*',
	]
}

const NUTCH_GOOGLE_ROBOTS = {
	Sitemap: `${process.env.URL}/sitemap.xml`,
	UserAgent: 'Nutch',
	Disallow: () => [
		'/'
	]
}

const AHREFSBOT_ROBOTS = {
	Sitemap: `${process.env.URL}/sitemap.xml`,
	UserAgent: 'AhrefsBot',
	CrawlDelay: 10,
	Disallow: () => [
		'/admin',
		'/cart',
		'/orders',
		'/checkouts/',
		'/checkout',
		'/10043036/checkouts',
		'/10043036/orders',
		'/carts',
		'/account',
		'/collections/*sort_by*',
		'/*/collections/*sort_by*',
		'/collections/*+*',
		'/collections/*%2B*',
		'/collections/*%2b*',
		'/*/collections/*+*',
		'/*/collections/*%2B*',
		'/*/collections/*%2b*',
		'/blogs/*+*',
		'/blogs/*%2B*',
		'/blogs/*%2b*',
		'/*/blogs/*+*',
		'/*/blogs/*%2B*',
		'/*/blogs/*%2b*',
		'/*?*oseid=*',
		'/*preview_theme_id*',
		'/*preview_script_id*',
		'/policies/',
		'/*/*?*ls=*&ls=*',
		'/*/*?*ls%3D*%3Fls%3D*',
		'/*/*?*ls%3d*%3fls%3d*',
		'/search',
		'/apple-app-site-association',
	]
}

const AHREFSSITEAUDIT_ROBOTS = {
	Sitemap: `${process.env.URL}/sitemap.xml`,
	UserAgent: 'AhrefsSiteAudit',
	CrawlDelay: 10,
	Disallow: () => [
		'/admin',
		'/cart',
		'/orders',
		'/checkouts/',
		'/checkout',
		'/10043036/checkouts',
		'/10043036/orders',
		'/carts',
		'/account',
		'/collections/*sort_by*',
		'/*/collections/*sort_by*',
		'/collections/*+*',
		'/collections/*%2B*',
		'/collections/*%2b*',
		'/*/collections/*+*',
		'/*/collections/*%2B*',
		'/*/collections/*%2b*',
		'/blogs/*+*',
		'/blogs/*%2B*',
		'/blogs/*%2b*',
		'/*/blogs/*+*',
		'/*/blogs/*%2B*',
		'/*/blogs/*%2b*',
		'/*?*oseid=*',
		'/*preview_theme_id*',
		'/*preview_script_id*',
		'/policies/',
		'/*/*?*ls=*&ls=*',
		'/*/*?*ls%3D*%3Fls%3D*',
		'/*/*?*ls%3d*%3fls%3d*',
		'/search',
		'/apple-app-site-association',
	]
}

const MJ12BOT_ROBOTS = {
	UserAgent: 'MJ12bot',
	CrawlDelay: 10
}

const PINTEREST_ROBOTS = {
	UserAgent: 'Pinterest',
	CrawlDelay: 1
}

export const ROBOTS_TXT = () => [
	ALL_ROBOTS,
	MJ12BOT_ROBOTS,
	AHREFSBOT_ROBOTS,
	PINTEREST_ROBOTS,
	NUTCH_GOOGLE_ROBOTS,
	ADSBOT_GOOGLE_ROBOTS,
	AHREFSSITEAUDIT_ROBOTS
]

// Try to make the CMS_SITE by reading liquid locale variables to support
// multiple lanaguages per store.  Otherwise, fallback to the ENV var
export const CMS_SITE = (() => {

	// Treat dev as dev
	if (process.env.CMS_SITE == 'dev') return 'dev'

	// Use window liquid vars
	if (typeof window !== "undefined"
		&& window.languageCode
		&& window.countryCode) {
		return [
			window.languageCode.toLowerCase(),
			window.countryCode.toUpperCase(),
		].join('_')
	}

	// Else, passthrough the ENV var, falling back to US
	return process.env.CMS_SITE || 'en_US'

})()
