import ShopifyTealiumInstrumentor from 'library/plugins/shopify-tealium-instrumentor'

export default ({ store, $storefront, i18n }, inject ) ->

	# Abort if we don't have tealium environment setup
	return unless tealiumEnv = process.env.TEALIUM_ENV

	# Disable automatic page tracking
	window.utag_cfg_ovrd = window.utag_cfg_ovrd || {}
	window.utag_cfg_ovrd.noview = true

	# Build out script URL with account, profile, and environment
	tealiumScriptUrl = "https://tags.tiqcdn.com/utag/owletbabycare/main/#{tealiumEnv}/utag.js"

	# Create the tealium script
	script = document.createElement "script"
	script.type = "text/javascript"
	script.innerHTML = """
		(function(a,b,c,d){
			a="#{tealiumScriptUrl}";
			b=document;c='script';d=b.createElement(c);d.src=a;
			d.type='text/java'+c;d.async=true;
			a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
		})();
	"""

	# Insert script at the start of the body
	document.body.insertBefore script, document.body.firstChild

	# Lookup the current currency
	{ currency: currencyCode } = i18n.locales.find ({ code }) ->
		code == i18n.locale

	# Inject tealium instrumentor
	inject 'tealiumEcomm', tealiumEcomm = new ShopifyTealiumInstrumentor {
		debug: process.env.APP_ENV == 'dev'
		$storefront
		currencyCode
	}
