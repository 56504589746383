###
Discount code related services
###

# Look for a "discount" query param and try to apply it to the cart
export watchForQueryDiscountCodes = (store, router) ->
	router.afterEach (to) ->
		applyDiscount store, to.query.discount
	, immediate: true

# Try to apply a discount code and alert an error if not valid
export applyDiscount = (store, code) ->
	return unless code

	# Apply the code
	await store.dispatch 'cart/applyDiscount', { code }


	# 5/20/20 The cart auto open on success was removed as per request from client
	# https://app.asana.com/0/1200777795870740/1202262396705104/f
	# # If this code was added to the cart succesfully, open the cart to show it
	# cartCodes = store.state.cart.discountCodes
	# if !!cartCodes.find (cartCode) -> cartCode.code == code
	# 	store.commit 'cart/open'

# Check if discounts is empty
export hasDiscounts = (store) ->
	return store.state.cart.discountCodes.length >= 1
